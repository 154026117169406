import Home from "./index";

const HomeRoutes = [
  {
    component: Home,
    path: "/",
    exact: true,
    type: "public",
  },
];

export default HomeRoutes;
