import Wise from "./index";

const WiseRoutes = [
  {
    component: Wise,
    path: "/wise",
    exact: true,
    type: "private",
  },
];

export default WiseRoutes;
